svg.spinner {
  display: block;
  margin: auto;
  background-color: rgba(255, 255, 255, 0);
}

/* Overlay styles */
.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.75);
  opacity: 0;
  transition: opacity 0.5s;
}
.overlay.show {
  opacity: 1;
}
.overlay .overlay-content {
  width: 100%;
  transform: scale(0.8);
  transition: transform 0.8s;
  font-family: Roboto, sans-serif;
  text-align: center;
}
.overlay.show .overlay-content {
  transform: scale(1);
}

.overlay footer {
  margin-top: 0;
  background-color: transparent;
}

.cc-icon {
  width: 30px;
  height: auto;
  padding: 0 2px;
  margin-right: 4px;
}
