@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@media (max-width: 510px) {
  select,
  select:focus,
  textarea,
  textarea:focus,
  input,
  input:focus {
    font-size: 13px;
  }
}

html,
body {
  font-family: 'Roboto', sans-serif !important;
  overflow: hidden;
}

.PhoneInputCountry {
  margin-right: 5px !important;
  margin-right: 10px !important;
}

.PhoneInputInput,
.PhoneInput--focus {
  outline: none !important;
  font-weight: bold;
}

.PhoneInput {
  width: 100%;
  flex-direction: row-reverse;
  font-size: small;
  font-weight: bold;
}

footer .terms {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  text-align: center;
  margin: 0 20px;
}

footer .terms a:link {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}
svg.spinner {
  display: block;
  margin: auto;
  background-color: rgba(255, 255, 255, 0);
}

/* Overlay styles */
.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.75);
  opacity: 0;
  transition: opacity 0.5s;
}
.overlay.show {
  opacity: 1;
}
.overlay .overlay-content {
  width: 100%;
  transform: scale(0.8);
  transition: transform 0.8s;
  font-family: Roboto, sans-serif;
  text-align: center;
}
.overlay.show .overlay-content {
  transform: scale(1);
}

.overlay footer {
  margin-top: 0;
  background-color: transparent;
}

.cc-icon {
  width: 30px;
  height: auto;
  padding: 0 2px;
  margin-right: 4px;
}

